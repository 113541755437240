

































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Dashboard extends Vue {
  selectedTab = '/datasources'
}
